<template>
    <div class="zain-chat chat">
        <beautiful-chat
            ref="beautifulChat"
            :participants="participants"
            :onMessageWasSent="onMessageWasSent"
            :messageList="messages"
            :newMessagesCount="newMessagesCount"
            :isOpen="isChatOpen"
            :close="closeChat"
            :open="openChat"
            :showEmoji="false"
            :showFile="true"
            :showEdition="false"
            :showDeletion="true"
            :showTypingIndicator="showTypingIndicator"
            :showLauncher="false"
            :showCloseButton="false"
            :colors="colors"
            :alwaysScrollToBottom="alwaysScrollToBottom"
            :disableUserListToggle="false"
            :messageStyling="messageStyling"
            :showHeader="false"
            @remove="removeMessage"
            @scrollToTop="scrollToTop"
            :placeholder="$t('Write a message')"
        >
            <template v-slot:user-avatar="user">
                <div
                    v-if="user.user.photo"
                    class="sc-message--avatar has-tooltip"
                    :data-original-title="user.user.display_name"
                    v-bind:style="{
                        'background-image': `url(${user.user.photo}`,
                    }"
                ></div>
                <div
                    v-else
                    class="sc-message--avatar has-tooltip"
                    :data-original-title="user.user.display_name"
                    v-bind:style="{ 'background-image': `url(${userImage}` }"
                ></div>
            </template>
            <template v-slot:text-message-body="scopedProps">
                <p
                    v-if="scopedProps.message.data.file"
                    class="sc-message--text-content"
                >
                    <a
                        style="color: #c1c3ce;"
                        :href="`${scopedProps.message.data.file.url}`"
                        target="_blank"
                        >{{ scopedProps.message.data.file.name }}</a
                    >
                </p>
                <p
                    class="sc-message--text-content"
                    v-html="scopedProps.messageText"
                ></p>
                <p
                    v-if="scopedProps.message.created"
                    class="sc-message--meta"
                    :style="{ color: scopedProps.messageColors.color }"
                >
                    {{ scopedProps.message.created | dateDayMonthYear }}
                    {{ scopedProps.message.created | dateTime }}
                </p>
            </template>
        </beautiful-chat>
    </div>
</template>
<script>
import memberImage from '@/assets/images/svg/person.svg'
import { ChatHelper } from '@/common/crud-helpers/chat'
import { API_BASE } from '@/conf'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
export default {
    props: {
        messageList: Array,
        participants: Array,
        loadding: Boolean,
    },
    data() {
        return {
            apiUrl: API_BASE,
            userImage: memberImage,
            newMessagesCount: 0,
            isChatOpen: true, // to determine whether the chat window should be open or closed
            showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
            colors: {
                header: {
                    bg: '#4e8cff',
                    text: '#ffffff',
                },
                launcher: {
                    bg: '#4e8cff',
                },
                messageList: {
                    bg: '#f7f7f9',
                },
                sentMessage: {
                    bg: '#491885',
                    text: '#ffffff',
                },
                receivedMessage: {
                    bg: '#ffffff',
                    text: '#000000',
                },
                userInput: {
                    bg: '#ffffff',
                    text: '#565867',
                },
            },
            alwaysScrollToBottom: true,
            messageStyling: true,
            messages: [],
            scrollHeight: 0,
        }
    },
    mounted() {},
    methods: {
        onMessageWasSent(message) {
            if (message.type == 'file') {
                ChatHelper.fileUpload({
                    file: message.data.file,
                }).then(resp => {
                    message.data.file = {}
                    message.data.file.id = resp.data
                    this.$emit('sendMessage', message)
                    message.author = 'me'
                })
            } else {
                this.$emit('sendMessage', message)
                message.author = 'me'
            }
            this.scrollHeight = 0
        },
        openChat() {},
        closeChat() {},
        scrollToTop() {
            // messageList.scrollTop + messageList.clientHeight - messageList.scrollHeight == 0 && !this.loadding
            let messageList = document.querySelector('.sc-message-list')
            this.scrollHeight = messageList.scrollHeight
            this.$emit('lodaNext')
        },
        removeMessage(message) {
            this.$emit('removeMessage', message)
        },
    },
    watch: {
        messageList() {
            let messages = cloneDeep(this.messageList)
            this.messages = _.orderBy(messages, ['created'], ['asc'])
            let _this = this

            setTimeout(function() {
                let messageList = document.querySelector('.sc-message-list')
                if (messageList.scrollHeight - _this.scrollHeight) {
                    messageList.scrollTop =
                        messageList.scrollHeight - _this.scrollHeight
                }
            }, 100)
        },
    },
}
</script>
<style lang="scss" scoped>
.chat {
    h2 {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
    }
}
</style>
<style lang="scss">
.zain-chat {
    .sc-chat-window {
        position: unset !important;
        width: 100%;
        box-shadow: none;
        background: none;
        .sc-message-list {
            padding: 0;
            background: #f7f7f9;
            border: 1px solid #e7e7e7;
            box-shadow: -10px 10px 12px rgba(0, 0, 0, 0.15);
            padding: 1rem 1.5rem;
            margin-bottom: 20px;
            .sc-message {
                width: 100%;
                .sc-message--text {
                    margin-right: 0;
                    max-width: 100%;
                    padding: 15px 15px;
                    .sc-message--meta {
                        text-align: right;
                    }
                    .sc-message--text-content {
                        margin: 0;
                        font-size: 1rem;
                        font-family: $primary-font;
                    }
                    .sc-message--meta {
                        color: #c1c3ce !important;
                        font-size: smaller;
                        margin: 0;
                    }
                }
                .received {
                    .sc-message--text {
                        border-radius: 0px 6px 6px 6px;
                        margin-left: 50px;
                    }
                }
                .sent {
                    .sc-message--text {
                        margin-left: 0;
                        border-radius: 6px 0px 6px 6px;
                    }
                }
                .sc-message--content {
                    position: relative;
                    .sc-message--avatar {
                        min-width: 40px;
                        min-height: 40px;
                        position: absolute;
                        top: 0;
                    }
                }
            }
        }
        > div:last-child {
            background: #f7f7f9;
            border: 1px solid #e7e7e7;
            box-shadow: -10px 10px 12px rgba(0, 0, 0, 0.15);
        }
        .sc-user-input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
.ar {
    .zain-chat {
        .sc-chat-window {
            .sc-message {
                .sc-message--text-content {
                    font-family: $arabic-font !important;
                }
            }
        }
    }
}
</style>
